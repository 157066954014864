import PouchDB from "pouchdb-core";
import idb from "pouchdb-adapter-idb";
import find from "pouchdb-find";
import replication from "pouchdb-replication";
import HttpPouch from "pouchdb-adapter-http";

import moment from "moment";
import path from "path";
import { _ } from "core-js";

PouchDB.plugin(idb);
PouchDB.plugin(find);
PouchDB.plugin(replication);
PouchDB.plugin(HttpPouch);

// console.log(data.gridData.defects_images);

// var db_defects_images = new PouchDB("defects_images");
// db_defects_images.bulkDocs(data.gridData.defects_images);
// getdata();
// async function getdata() {
//   await db_defects_images.createIndex({
//     index: { fields: ["image_id"] }
//   });
//   var data1 = await db_defects_images.find({
//     selector: {
//       image_id: { $eq: 6 }
//     }
//   });
//   console.log(data1);
// }
// var db_boxes = new PouchDB("boxes");
// db_boxes.bulkDocs(JSON.parse(data).gridData.boxes);
// var db_snapshots = new PouchDB("snapshots");
// db_snapshots.bulkDocs(JSON.parse(data).gridData.snapshots);
// var db_poles = new PouchDB("poles");
// db_poles.bulkDocs(JSON.parse(data).gridData.poles);
// var db_images = new PouchDB("images");
// db_images.bulkDocs(JSON.parse(data).gridData.images);
// var db_projects = new PouchDB("projects");
// db_projects.bulkDocs(data.gridData.projects);
// var db_defects = new PouchDB("defects");
// db_defects.bulkDocs(JSON.parse(data).gridData.defects);
// PouchDB.replicate("defects_images", "http://localhost:5984/defects_images", { live: true });
// PouchDB.replicate("boxes", "http://localhost:5984/boxes", { live: true });
// PouchDB.replicate("snapshots", "http://localhost:5984/snapshots", { live: true });
// PouchDB.replicate("poles", "http://localhost:5984/poles", { live: true });
// PouchDB.replicate("images", "http://localhost:5984/images", { live: true });
// PouchDB.replicate("projects", "http://localhost:5984/projects", { live: true });
// PouchDB.replicate("defects", "http://localhost:5984/defects", { live: true });

export default class DB {
  static Dbconnect = async dbname => {
    var db = new PouchDB(dbname);
    return db;
  };

  static createDatabase = async data => {
    await this.deleteDatabase();
    var boxdata = await this.formatData(data.boxes);
    var db_boxes = new PouchDB("boxes");
    db_boxes.bulkDocs(boxdata);

    var defects_imagesdata = await this.formatData(data.defects_images);
    var db_defects_images = new PouchDB("defects_images");
    db_defects_images.bulkDocs(defects_imagesdata);

    var snapshotsdata = await this.formatData(data.snapshots);
    var db_snapshots = new PouchDB("snapshots");
    db_snapshots.bulkDocs(snapshotsdata);

    var polesdata = await this.formatData(data.poles);
    var db_poles = new PouchDB("poles");
    db_poles.bulkDocs(polesdata);
    db_poles.createIndex({
      index: { fields: ["project_id", "pole_id"] }
    });

    var imagesdata = await this.formatData(data.images);
    var db_images = new PouchDB("images");
    db_images.bulkDocs(imagesdata);
    db_images.createIndex({
      index: { fields: ["pole_id"] }
    });

    var projectsdata = await this.formatData(data.projects);
    var db_projects = new PouchDB("projects");
    db_projects.bulkDocs(projectsdata);

    var defectsdata = await this.formatData(data.defects);
    var db_defects = new PouchDB("defects");
    db_defects.bulkDocs(defectsdata);
    db_defects.createIndex({
      index: { fields: ["pole_id"] }
    });
  };

  static deleteDatabase = async () => {
    try {
      var db_boxes = new PouchDB("boxes");
      var db_defects_images = new PouchDB("defects_images");
      var db_snapshots = new PouchDB("snapshots");
      var db_poles = new PouchDB("poles");
      var db_images = new PouchDB("images");
      var db_projects = new PouchDB("projects");
      var db_defects = new PouchDB("defects");

      await db_boxes.destroy();
      await db_defects_images.destroy();
      await db_snapshots.destroy();
      await db_poles.destroy();
      await db_images.destroy();
      await db_projects.destroy();
      await db_defects.destroy();
      return true;
    } catch (err) {
      // throw err;
      return true;
    }
  };

  static formatData = async data => {
    for (const key in data) {
      const element = data[key];
      data[key]._id = `${element.id}`;
    }
    return data;
  };

  static getProject = async () => {
    let db = await this.Dbconnect("projects");
    let result = await db.get("1");
    return result;
  };

  static getPolesByProjectId = async id => {
    var self = this;
    let db = await this.Dbconnect("poles");
    let data = await db.find({
      selector: {
        project_id: { $eq: id }
      }
    });
    for (const key in data.docs) {
      const element = data.docs[key];
      var images = await this.getImagesByPoleId(element.id);
      var uploadedImages = images.filter(image => image.uploaded == false);
      var thermalImages = images.filter(image => image.type == "thermal");
      if (thermalImages.length > 0) {
        var deltaT = Math.max.apply(
          Math,
          thermalImages.map(function (image) {
            return image.thermal_deltaT;
          })
        );
        data.docs.highestDeltaT = deltaT;
      } else {
        data.docs.highestDeltaT = -1;
      }
      data.docs.uploaded = uploadedImages.length > 0 ? false : true;
    }
    return data.docs;
  };

  static getImagesByPoleId = async id => {
    let db = await this.Dbconnect("images");
    let data = await db.find({
      selector: {
        pole_id: { $eq: `${id}` }
      }
    });
    data = data.docs;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        if (element.image_id == "") {
          data[key]["image_id"] = element.id;
        }
      }
    }
    return data;
  };

  static getPolesById = async (project_id, pole_id) => {
    let db = await this.Dbconnect("poles");
    db.createIndex({
      index: { fields: ["project_id", "id"] }
    });
    let pole = await db.find({
      selector: {
        id: { $eq: parseInt(pole_id) },
        project_id: { $eq: project_id }
      }
    });
    pole = pole.docs[0];
    var images = await this.getImagesByPoleId(pole.id);
    var thermalImages = images.filter(image => image.type == "thermal");
    if (thermalImages.length > 0) {
      var deltaT = Math.max.apply(
        Math,
        thermalImages.map(function (image) {
          return image.thermal_deltaT;
        })
      );
      pole.highestDeltaT = deltaT;
    } else {
      pole.highestDeltaT = -1;
    }
    return pole;
  };

  static getDefects = async pole_id => {
    let db = await this.Dbconnect("defects");
    db.createIndex({
      index: { fields: ["pole_id"] }
    });
    let data = await db.find({
      selector: {
        pole_id: { $eq: pole_id }
      }
    });
    // console.log(defects,"sdfdsf");
    return data.docs;
  };

  static async addImage(image) {
    let self = this;
    let db = await this.Dbconnect("images");
    return db.put(image);    
  };

  static async addDefect(defect) {
    var self = this;
    let db = await this.Dbconnect("defects");
    var _id = Date.now();
    var df = {
      component: defect.component,
      name: defect.name,
      severity: defect.severity,
      phase: defect.phase,
      remark: defect.remark,
      recommendation: defect.recommendation,
      pole_id: defect.pole_id,
      type: defect.type,
      created_at: defect.created_at,
      id: _id,
      _id: `${_id}`
    };
    var ids = await db.bulkDocs([df]);
    let db1 = await this.Dbconnect("defects_images");
    var _id1 = Date.now();
    var defectImage = await db1.bulkDocs([
      { defect_id: _id, image_id: defect.image_id, id: _id1, _id: `${_id1}` }
    ]);
    var newDefect = await db.find({
      selector: {
        id: { $eq: _id }
      }
    });
    newDefect = newDefect.docs;
    var resolveDefect = {
      newDefectId: _id,
      allDefect: newDefect[0]
    };
    //debugger;
    console.log(defectImage, ids);

    return resolveDefect;
  }

  static async getSnapShots(defect_id) {
    try {
      let db = await this.Dbconnect("snapshots");
      db.createIndex({
        index: { fields: ["defect_id", "position"] }
      });
      let data = await db.find({
        selector: {
          defect_id: { $eq: defect_id }
        }
      });

      return data.docs;
    } catch (err) {
      throw err;
    }
  }

  static async saveSnapShotPath(snapShot) {
    try {
      // debugger;
      let db = await this.Dbconnect("snapshots");
      db.createIndex({
        index: { fields: ["defect_id", "position"] }
      });
      let data = await db.find({
        selector: {
          defect_id: { $eq: snapShot.defect_id },
          position: { $eq: snapShot.position }
        }
      });
      console.log(data.docs, "find with defect id and position");
      data = data.docs;
      if (data.length) {
        data[0]["name"] = snapShot.name;
        await db.put(data[0]);
        var shapShots = await this.getSnapShots(snapShot.defect_id);
        console.log(shapShots);
        return shapShots;
      } else {
        // debugger;
        var _id = Date.now();
        snapShot._id = `${_id}`;
        snapShot.id = _id;
        await db.bulkDocs([snapShot]);
        var shapShots = await this.getSnapShots(snapShot.defect_id);
        console.log(shapShots);
        return shapShots;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async getPoleNumberImage(pole_id) {
    try {
      let db = await this.Dbconnect("images");
      let data = await db.find({
        selector: {
          pole_id: { $eq: `${pole_id}` },
          isPoleNumber: { $eq: 1 }
        }
      });
      return data.docs[0];
    } catch (err) {
      throw err;
    }
  }

  static async updatePoleProgress(pole_id, isCompleted) {
    try {
      let db = await this.Dbconnect("poles");
      let pole = await db.find({
        selector: {
          id: { $eq: pole_id }
        }
      });
      pole = pole.docs[0];
      pole["completed"] = isCompleted ? 1 : 0;
      console.log(pole);
      var data = await db.put(pole);
      console.log(data);

      return pole_id;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async getDefectsById(pole_id, defect_id) {
    try {
      let db = await this.Dbconnect("defects");
      db.createIndex({
        index: { fields: ["pole_id", "id"] }
      });
      let defects = await db.find({
        selector: {
          pole_id: { $eq: pole_id },
          id: { $eq: defect_id }
        }
      });
      defects = defects.docs;
      var defectlist = [];
      for (const key in defects) {
        if (defects.hasOwnProperty(key)) {
          const element = defects[key];
          let db1 = await this.Dbconnect("defects_images");
          db1.createIndex({
            index: { fields: ["defect_id", "id"] }
          });
          let defects_image = await db1.find({
            selector: {
              defect_id: { $eq: element.id }
            }
          });
          defects_image = defects_image.docs;
          for (const key1 in defects_image) {
            var defects_images = defects_image[key1];
            let db2 = await this.Dbconnect("images");
            await db2.createIndex({
              index: { fields: ["id"] }
            });
            let images = await db2.find({
              selector: {
                id: { $eq: defects_images.image_id }
              }
            });
            images = images.docs[0];
            defectlist[key1] = {};
            defectlist[key1]["component"] = element.component;
            defectlist[key1]["created_at"] = element.created_at;
            defectlist[key1]["id"] = element.id;
            defectlist[key1]["name"] = element.name;
            defectlist[key1]["phase"] = element.phase;
            defectlist[key1]["pole_id"] = element.pole_id;
            defectlist[key1]["recommendation"] = element.recommendation;
            defectlist[key1]["remark"] = element.remark;
            defectlist[key1]["severity"] = element.severity;
            defectlist[key1]["type"] = element.type;
            defectlist[key1]["defect_id"] = element.id;
            defectlist[key1]["defectType"] = element.type;
            defectlist[key1]["defects_images_id"] = defects_images.id;
            defectlist[key1]["crop_image_name"] = images
              ? images.crop_image_name
              : "";
            defectlist[key1]["imageName"] = images.name;
            defectlist[key1]["image_id"] = images.id;
            defectlist[key1]["type"] = images.type;
            defectlist[key1]["directory"] = images.directory;
            defectlist[key1]["image_url_large"] = images.image_url_large;
            defectlist[key1]["image_url_original"] = images.image_url_original;
            defectlist[key1]["image_url_med"] = images.image_url_med;
            defectlist[key1]["image_url_thumb"] = images.image_url_thumb;
            defectlist[key1]["crop_image_name"] = images.crop_image_name;
          }
        }
      }
      return defectlist;
    } catch (err) {
      throw err;
    }
  }
  static async getBoxesByImageId(id) {
    try {
      let db = await this.Dbconnect("boxes");
      db.createIndex({
        index: { fields: ["defects_images_id", "id"] }
      });
      let boxes = await db.find({
        selector: {
          defects_images_id: { $eq: id }
        }
      });
      boxes = boxes.docs;
      for (const key in boxes) {
        if (boxes.hasOwnProperty(key)) {
          const element = boxes[key];
          let db1 = await this.Dbconnect("defects_images");
          db1.createIndex({
            index: { fields: ["defect_id", "id"] }
          });
          let defects_images = await db1.find({
            selector: {
              id: { $eq: id }
            }
          });
          defects_images = defects_images.docs[0];
          let db2 = await this.Dbconnect("defects");
          db2.createIndex({
            index: { fields: ["id"] }
          });
          let defects = await db2.find({
            selector: {
              id: { $eq: defects_images.defect_id }
            }
          });
          defects = defects.docs[0];
          boxes[key]["defectId"] = defects.id;
          boxes[key]["name"] = defects.name;
          boxes[key]["component"] = defects.component;
          boxes[key]["severity"] = defects.severity;
        }
      }
      return boxes;
    } catch (err) {
      throw err;
    }
  }

  static async saveBox(id, opts) {
    try {
      let db = await this.Dbconnect("boxes");
      if (id) {
        db.createIndex({
          index: { fields: ["defects_images_id", "id"] }
        });
        let boxes = await db.find({
          selector: {
            id: { $eq: id }
          }
        });
        boxes = boxes.docs;
        if (boxes.length == 0) {
          throw `No box with id of ${id}`;
        }
        boxes = boxes[0];
        boxes.x = opts.x;
        boxes.y = opts.y;
        boxes.width = opts.width;
        boxes.height = opts.height;
        boxes.severity = opts.severity;
        var data = await db.put(boxes);
        console.log(data);

        // debugger;
        return id;
      } else {
        var _id = Date.now();
        opts._id = `${_id}`;
        opts.id = _id;
        var data = await db.bulkDocs([opts]);
        console.log(data);

        // debugger;
        return _id;
      }
    } catch (err) {
      throw err;
    }
  }

  static async deleteBox(opts) {
    try {
      var id = opts.boxId;
      let db = await this.Dbconnect("boxes");
      if (id) {
        db.createIndex({
          index: { fields: ["defects_images_id", "id"] }
        });
        let boxes = await db.find({
          selector: {
            id: { $eq: id }
          }
        });
        boxes = boxes.docs;
        if (boxes.length == 0) {
          throw `No box with id of ${id}`;
        }
        boxes = boxes[0];
        await db.remove(boxes);
        return id;
      } else {
        return true;
      }
    } catch (err) {
      throw err;
    }
  }

  static async updatePoleSpanType(poles, type) {
    try {
      let db = await this.Dbconnect("poles");
      let pole = await db.find({
        selector: {
          id: { $eq: poles.id }
        }
      });
      pole = pole.docs[0];
      pole.span_type = type;
      var updated = await db.put(pole);
      return type;
    } catch (err) {
      throw err;
    }
  }
  static async updatePoleEquipments(poles, equipments) {
    try {
      let db = await this.Dbconnect("poles");
      let pole = await db.find({
        selector: {
          id: { $eq: poles.id }
        }
      });
      pole = pole.docs[0];
      pole.equipments = equipments;
      var updated = await db.put(pole);
      return equipments;
    } catch (err) {
      throw err;
    }
  }

  static async deleteSnapshot(snapShot) {
    try {
      let db = await this.Dbconnect("snapshots");
      db.createIndex({
        index: { fields: ["defect_id", "position", "id"] }
      });
      let data = await db.find({
        selector: {
          id: { $eq: snapShot.id }
        }
      });
      await db.remove(data.docs[0]);
      return true;
    } catch (err) {
      // alert(err.message)
      throw err;
    }
  }

  static async deleteDefect(defect, pole_id) {
    try {
      console.log(defect);
      let db = await this.Dbconnect("defects");
      await db.createIndex({
        index: { fields: ["pole_id", "id"] }
      });
      let data = await db.find({
        selector: {
          id: { $eq: defect.id }
        }
      });
      const defectTodelete = data.docs[0];
      if (defectTodelete) {
        console.log("delete defect");
        var defect_id = defectTodelete.id;
        var db_defects_images = await this.Dbconnect("defects_images");
        await db_defects_images.createIndex({
          index: { fields: ["defect_id", "id"] }
        });
        let defectImages = await db_defects_images.find({
          selector: {
            defect_id: { $eq: defect_id }
          }
        });
        defectImages = defectImages.docs;
        let db_boxes = await this.Dbconnect("boxes");
        await db_boxes.createIndex({
          index: { fields: ["defects_images_id", "id"] }
        });
        for (const iterator of defectImages) {
          let boxes = await db_boxes.find({
            selector: {
              defects_images_id: { $eq: iterator.id }
            }
          });
          boxes = boxes.docs;
          for (const iterator1 of boxes) {
            await db_boxes.remove(iterator1);
          }
          await db_defects_images.remove(iterator);
        }
        let db_snapshots = await this.Dbconnect("snapshots");
        await db_snapshots.createIndex({
          index: { fields: ["defect_id", "id"] }
        });
        let snapshots = await db_snapshots.find({
          selector: {
            defect_id: { $eq: defect_id }
          }
        });
        snapshots = snapshots.docs;
        for (const iterator of snapshots) {
          await db_snapshots.remove(iterator);
        }
        await db.remove(defectTodelete);
        var defects = await this.getDefects(pole_id);
        return defects;
      } else {
        return false;
      }
    } catch (err) {
      throw err;
    }
  }

  static async updateImagePoleNumber(currentImage, newImage) {
    try {
      console.log(newImage.name);
      var db = await this.Dbconnect("images");
      await db.createIndex({
        index: { fields: ["id"] }
      });
      var image = await db.find({
        selector: {
          id: { $eq: newImage.id }
        }
      });
      if (image.docs.length > 0) {
        image = image.docs[0];
        image.isPoleNumber = true;
        var updated = await db.put(image);
        if (updated && typeof currentImage !== "undefined") {
          var oldimage = await db.find({
            selector: {
              id: { $eq: currentImage.id }
            }
          });
          oldimage = oldimage.docs[0];
          oldimage.isPoleNumber = false;
          var oldupdated = await db.put(oldimage);
        }
        return true;
      } else {
        throw "img not found!!";
      }
    } catch (err) {
      throw err;
    }
  }

  static async addDefectImages(pole_id, defectImages) {
    try {
      console.log(pole_id);
      console.log(defectImages);

      var existingDefectImages = [];
      var db_defects = await this.Dbconnect("defects_images");
      await db_defects.createIndex({
        index: { fields: ["defect_id", "image_id"] }
      });
      var defects_images = await db_defects.find({
        selector: {
          defect_id: { $eq: defectImages[0].defect_id }
        }
      });
      // debugger;
      defects_images = defects_images.docs;
      existingDefectImages = defects_images;
      console.log(existingDefectImages);
      var removedImages = [];
      existingDefectImages.map(existingDefectImage => {
        if (
          typeof defectImages.find(
            img =>
              img.image_id == existingDefectImage.image_id &&
              img.defect_id == existingDefectImage.defect_id
          ) === "undefined"
        ) {
          removedImages.push(existingDefectImage);
        }
      });
      console.log("removedImages");
      console.log(removedImages);
      for (const iterator of removedImages) {
        await db_defects.remove(iterator);
      }
      for (const iterator1 of defectImages) {
        var findDefectImage = await db_defects.find({
          selector: {
            defect_id: { $eq: iterator1.defect_id },
            image_id: { $eq: iterator1.image_id }
          }
        });
        findDefectImage = findDefectImage.docs;
        if (findDefectImage.length == 0) {
          var _id = Date.now();
          iterator1._id = `${_id}`;
          iterator1.id = _id;
          var insert = await db_defects.bulkDocs([iterator1]);
          console.log(insert);
        }
      }
      var defects = await this.getDefectsById(
        pole_id,
        defectImages[0].defect_id
      );
      console.log(defects);

      return defects;
    } catch (err) {
      throw err;
    }
  }

  static async getCompletedPolesImagesByProjectId(project_id, reuploadAll) {
    try {
      console.log(project_id);
      var db_poles = await this.Dbconnect("poles");
      await db_poles.createIndex({
        index: { fields: ["project_id", "completed"] }
      });
      var db_images = await this.Dbconnect("images");
      await db_images.createIndex({
        index: { fields: ["pole_id", "uploaded", "id"] }
      });
      var db_defects = await this.Dbconnect("defects");
      await db_defects.createIndex({
        index: { fields: ["pole_id"] }
      });
      var db_defects_images = await this.Dbconnect("defects_images");
      await db_defects_images.createIndex({
        index: { fields: ["defect_id"] }
      });
      var poles = await db_poles.find({
        selector: {
          project_id: { $eq: project_id },
          completed: { $eq: 1 }
        }
      });
      poles = poles.docs;
      console.log(poles);
      for (const key in poles) {
        var pole = poles[key];
        console.log(reuploadAll);
        var query = reuploadAll
          ? { pole_id: { $eq: `${pole.id}` } }
          : { pole_id: { $eq: `${pole.id}` }, uploaded: { $eq: 0 } };
        console.log(query);
        var images = await db_images.find({
          selector: query
        });
        images = images.docs;
        var visuals = images.filter(image => image.type == "visual");
        var thermals = images.filter(image => image.type == "thermal");
        poles[key].visuals = visuals;
        poles[key].thermals = thermals;
        var defects = await db_defects.find({
          selector: {
            pole_id: { $eq: pole.id }
          }
        });
        defects = defects.docs;
        // debugger
        for (const key1 in defects) {
          if (defects.hasOwnProperty(key1)) {
            const element = defects[key1];
            console.log(element.id, "element.id");
            defects[key1].snapshots = await this.getSnapShotsNotUploaded(
              element.id
            );
            var defects_images = await db_defects_images.find({
              selector: {
                defect_id: { $eq: element.id }
              }
            });
            defects_images = defects_images.docs;
            for (const defects_image of defects_images) {
              var defects_images_defect = await db_images.find({
                selector: {
                  id: { $eq: defects_image.image_id }
                }
              });
              defects_images_defect = defects_images_defect.docs[0];
              defects_images_defect.defects_images_id = defects_image.id;
              defects[key1].images = defects_images_defect;
            }
          }
        }
        var visuals = defects.filter(defect => defect.type == "visual");
        var thermals = defects.filter(defect => defect.type == "thermal");
        poles[key].defectsVisual = visuals;
        poles[key].defectsThermal = thermals;
      }
      return poles;
    } catch (err) {
      // debugger;
      throw err;
    }
  }
  static async getImagesByProjectId(project_id) {
    try {
      console.log(project_id);
      var db_poles = await this.Dbconnect("poles");
      await db_poles.createIndex({
        index: { fields: ["project_id", "completed"] }
      });
      var db_images = await this.Dbconnect("images");
      await db_images.createIndex({
        index: { fields: ["pole_id", "uploaded", "id"] }
      });

      var poles = await db_poles.find({
        selector: {
          project_id: { $eq: project_id }
        }
      });
      poles = poles.docs;
      var Allimages = [];
      for (const key in poles) {
        var pole = poles[key];
        var images = await db_images.find({
          selector: { pole_id: { $eq: `${pole.id}` } }
        });
        images = images.docs;
        for (const iterator of images) {
          Allimages.push(iterator);
        }
      }
      return Allimages;
    } catch (err) {
      throw err;
    }
  }
  static async getSnapShotsNotUploaded(defect_id) {
    try {
      console.log("getSnapShots");
      console.log(defect_id);
      var db_snapshots = await this.Dbconnect("snapshots");
      await db_snapshots.createIndex({
        index: { fields: ["defect_id", "uploaded"] }
      });
      var snapshots = await db_snapshots.find({
        selector: { defect_id: { $eq: defect_id } }
      });
      snapshots = snapshots.docs;
      return snapshots;
    } catch (err) {
      throw err;
    }
  }
  static async updateUploadedSnapshotUrls(snapshot, urls) {
    try {
      // debugger;
      console.log(snapshot);
      // debugger;
      var db_snapshots = await this.Dbconnect("snapshots");
      await db_snapshots.createIndex({
        index: { fields: ["defect_id", "uploaded"] }
      });
      snapshot.url_large = urls.full;
      snapshot.url_med = urls.medium;
      snapshot.url_thumb = urls.thumb;
      var data = await db_snapshots.put(snapshot);
      // debugger;
      console.log(data);

      return snapshot;
    } catch (err) {
      throw err;
    }
  }
  static async getallData() {
    try {
      var db_boxes = new PouchDB("boxes");
      var db_defects_images = new PouchDB("defects_images");
      var db_snapshots = new PouchDB("snapshots");
      var db_poles = new PouchDB("poles");
      var db_images = new PouchDB("images");
      var db_projects = new PouchDB("projects");
      var db_defects = new PouchDB("defects");

      var boxes = await db_boxes.allDocs({
        include_docs: true,
        endkey: "_design",
        "options.inclusive_end": false
      });
      var defects_images = await db_defects_images.allDocs({
        include_docs: true,
        endkey: "_design",
        "options.inclusive_end": false
      });
      var snapshots = await db_snapshots.allDocs({
        include_docs: true,
        endkey: "_design",
        "options.inclusive_end": false
      });
      var poles = await db_poles.allDocs({
        include_docs: true,
        endkey: "_design",
        "options.inclusive_end": false
      });
      var images = await db_images.allDocs({
        include_docs: true,
        endkey: "_design",
        "options.inclusive_end": false
      });
      var projects = await db_projects.allDocs({
        include_docs: true,
        endkey: "_design",
        "options.inclusive_end": false
      });
      var defects = await db_defects.allDocs({
        include_docs: true,
        endkey: "_design",
        "options.inclusive_end": false
      });
      return {
        defects_images: defects_images.rows.map(x => x.doc),
        boxes: boxes.rows.map(x => x.doc),
        snapshots: snapshots.rows.map(x => x.doc),
        poles: poles.rows.map(x => x.doc),
        images: images.rows.map(x => x.doc),
        projects: projects.rows.map(x => x.doc),
        defects: defects.rows.map(x => x.doc)
      };
    } catch (error) { }
  }
  static async updatePoleInspectionType(pole, type) {
    try {
      var db_poles = await this.Dbconnect("poles");
      await db_poles.createIndex({
        index: { fields: ["id"] }
      });
      var pole = await db_poles.find({ selector: { id: { $eq: pole.id } } });
      pole = pole.docs[0];
      pole.inspection_type = type;
      await db_poles.put(pole);
      return type;
    } catch (err) {
      throw err;
    }
  }
  static async updatePoleType(pole, type) {
    try {
      var db_poles = await this.Dbconnect("poles");
      await db_poles.createIndex({
        index: { fields: ["id"] }
      });
      var pole = await db_poles.find({ selector: { id: { $eq: pole.id } } });
      pole = pole.docs[0];
      pole.pole_type = type;
      await db_poles.put(pole);
      return type;
    } catch (err) {
      throw err;
    }
  }
  static async updateDefectPhase(id, phase) {
    try {
      var db_defects = await this.Dbconnect("defects");
      await db_defects.createIndex({
        index: { fields: ["id"] }
      });
      var defects = await db_defects.find({ selector: { id: { $eq: id } } });
      defects = defects.docs[0];
      defects.phase = phase;
      await db_defects.put(defects);
      return phase;
    } catch (err) {
      throw err;
    }
  }
  static async updateImageCroppedPath(id, img_path) {
    try {
      var db_images = await this.Dbconnect("images");
      await db_images.createIndex({
        index: { fields: ["id"] }
      });
      var images = await db_images.find({ selector: { id: { $eq: id } } });
      images = images.docs[0];
      images.crop_image_name = img_path;
      await db_images.put(images);
      console.log(images);

      return images;
    } catch (err) {
      throw err;
    }
  }
  static async deleteImage(image, pole_id) {
    try {
      console.log(image);
      var db_images = await this.Dbconnect("images");
      await db_images.createIndex({
        index: { fields: ["id"] }
      });
      var images = await db_images.find({
        selector: { id: { $eq: image.id } }
      });
      images = images.docs[0];
      if (imageTodelete) {
        console.log("delete image");
        var image_id = imageTodelete.id;
        var db_defects_images = await this.Dbconnect("defects_images");
        await db_defects_images.createIndex({
          index: { fields: ["image_id"] }
        });
        var defects_images = await db_defects_images.find({
          selector: { image_id: { $eq: image_id } }
        });
        defects_images = defects_images.docs;
        var db_boxes = await this.Dbconnect("boxes");
        await db_boxes.createIndex({
          index: { fields: ["defects_images_id"] }
        });
        for (const iterator of defects_images) {
          var boxes = await db_boxes.find({
            selector: { defects_images_id: { $eq: iterator.id } }
          });
          for (const iterator1 of boxes) {
            await db_boxes.remove(iterator1);
          }
          await db_defects_images.remove(iterator);
        }
        await db_images.remove(images);
      }
      return true;
    } catch (err) {
      throw err;
    }
  }
}
